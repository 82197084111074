import { useEffect, useState } from "react";
import "./Subscriptions.scss";
import { useSelector } from "react-redux";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import toastMessage from "utils/toast";
import { getErrorMessage } from "utils/helper";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Button, Typography, Card } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import AddNewUserModal from "./AddNewUserModal/AddNewUserModal";

const Index = () => {
  const { user } = useSelector((state: any) => state.auth);
  const [addEmployee, setAddEmployee] = useState(false);

  const cardDetails = [
    {
      name: "Free",
      features: [
        {
          name: "Limited employees",
          feat: [
            "First 4 employees are free",
            "Small businesses and startups",
            "Basic employee management needs",
            "Exploring essential HR tools at no cost",
          ],
        },
        {
          name: "Teams",
          feat: [
            "Up to 5 team members",
            "For small teams or startups on a budget",
            "For basic collaboration needs",
            "For exploring team collaboration software",
          ],
        },
      ],
    },
    {
      name: "Premium",
      features: [
        {
          name: "Unlimited employees",
          feat: [
            "N500 each on employees above 4",
            "Growing businesses and enterprises",
            "Comprehensive employee management",
            "Organizations prioritizing employee engagement",
          ],
        },
        {
          name: "Teams",
          feat: [
            "Unlimited team members",
            "Growing businesses and established teams",
            "Advanced collaboration needs",
            "Teams looking for a productivity suite",
          ],
        },
      ],
    },
  ];

  const getAllCards = () => {
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.getAllCards,
    })
      .then((resp) => {})
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        toastMessage(errMsg, true);
      });
  };

  useEffect(() => {
    getAllCards();
  }, []);

  const AddCard = (refrenece: any) => {
    const body = {
      transaction_ref: refrenece,
      company: user?.employee?.company?.id,
    };
    API({
      method: httpVerbs?.POST,
      url: apiUrls?.addCard,
      data: body,
    })
      .then((resp) => {
        getAllCards();
        toastMessage("Card Added Successfully!");
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        toastMessage(errMsg, true);
      });
  };

  const onSuccess = (reference: any) => {
    AddCard(reference);
  };

  const handleClose = () => {
    console.log("closed");
  };

  // Config
  const componentProps = {
    email: user?.email,
    amount: 500?.toFixed(2),
    publicKey: "pk_live_b9be070466db99b8a785e50383b95aa0a6872d47",
    // publicKey: "pk_test_6b44fd2bf2c45c1f677ec8b8463a5ecd8fcf0421",
    channels: ["card"],

    metadata: {
      name: `${user?.first_name} ${user?.last_name}`,
    },
    onSuccess: (response: any) => {
      onSuccess(response?.reference);
    },
    onClose: handleClose,
  };

  return (
    <div>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          py: "2rem",
          border: "2px solid #002C61",
          borderRadius: "10px",
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Pay for Use
        </Typography>
        <Typography>Pay for what you use at the end of the month</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 3,
        }}
      >
        <Typography
          sx={{
            width: "40%",
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "medium",
          }}
        >
          Get the most out of Sprintster affordable subscription features for
          your projects.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
          my: 4,
        }}
      >
        {cardDetails?.map((details, index: number) => (
          <Box
            key={index}
            sx={{
              backgroundColor:
                details?.name === "Premium" ? "#ced5e2" : "#E0EDF7",
              borderRadius: "20px",
              padding: "1rem",
              mx: 1,
            }}
          >
            <Card
              sx={{
                width: "390px",
                height: "470px",
                borderRadius: "20px",
                border: details?.name === "Premium" ? "4px solid #002C61" : "",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor:
                    details?.name === "Premium" ? "#002C61" : "#c1def4",
                  py: 1,
                }}
              >
                {details?.name === "Premium" && (
                  <DiamondOutlinedIcon sx={{ color: "#FFFFFF", mr: 1 }} />
                )}
                <Typography
                  sx={{
                    backgroundColor:
                      details?.name === "Premium" ? "#002C61" : "#c1def4",
                    color: details?.name === "Premium" ? "#FFFFFF" : "#000000",
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "center",
                    py: 1,
                  }}
                >
                  {details?.name}
                </Typography>
              </Box>
              <Box sx={{ m: 3 }}>
                {details?.features?.map((feature, idx: number) => (
                  <Box key={idx} sx={{ mb: 2 }}>
                    <Box sx={{ display: "flex" }}>
                      <CheckCircleOutlineIcon
                        sx={{
                          color:
                            details?.name === "Premium" ? "#02CA16" : "#5a5959",
                        }}
                      />
                      <Typography
                        sx={{
                          ml: 2,
                          fontWeight: "bold",
                          fontSize: "16px",
                          color: "#5a5959",
                        }}
                      >
                        {feature?.name}
                      </Typography>
                    </Box>
                    <Box sx={{ ml: 4.8 }}>
                      {feature?.feat?.map((item, indx: number) => (
                        <Box
                          key={indx}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <FiberManualRecordIcon
                            sx={{ width: 10, height: 10, color: "#5a5959" }}
                          />
                          <Typography
                            sx={{
                              ml: 1,
                              fontSize: "12px",
                              my: 0.5,
                              color: "#494747",
                            }}
                          >
                            {item}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
              {details?.name === "Premium" && (
                <Button
                  variant="contained"
                  sx={{
                    width: "90%",
                    mx: 2,
                    py: 1.2,
                    backgroundColor: "#008FE3",
                    textTransform: "inherit",
                    fontSize: "18px",
                  }}
                  // endIcon={<ArrowCircleRightOutlinedIcon />}
                  // onClick={() => setAddEmployee(true)}
                  disabled={true}
                >
                  Coming Soon...
                </Button>
              )}
            </Card>
          </Box>
        ))}
      </Box>
      <AddNewUserModal
        isOpen={addEmployee}
        onClose={() => setAddEmployee(false)}
      />
    </div>
  );
};

export default Index;
