import Input from "components/ui/Input";
import Select from "components/ui/Select";
import CustomSelect from "components/ui/Select/CustomSelect";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apiUrls, httpVerbs } from "utils/api";
import { formatDate } from "utils/helper";
import API from "utils/api/API";
import "../ProjectStyle.scss";
import { Button } from "components/ui";
import assets from "assets";

const CreateProjectForm = (props: {
  register: Function;
  errors: any;
  isEdit?: boolean;
  repositories: any;
  team: any;
  setRepositories: Function;
  repos: any;
  watch: Function;
  users: any;
  handleClose: Function;
  project_owner: any;
  setProject_owner: any;
  team_: any;
  setTeam: any;
  error: boolean;
  handleUserModalOpen: any;
  handleTeamModalOpen: any;
}) => {
  const {
    register,
    errors,
    isEdit,
    repositories,
    setRepositories,
    repos,
    watch,
    users,
    handleClose,
    project_owner,
    setProject_owner,
    team_,
    setTeam,
    error,
    handleUserModalOpen,
    handleTeamModalOpen,
  } = props;

  const { user } = useSelector((state: any) => state.auth);

  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);

  const getTeams = () => {
    setLoading(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.teams_name,
    })
      .then((resp) => {
        setLoading(false);
        setTeams(resp?.data?.data);
        // console.log('team name,', results)
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTeams();
    // eslint-disable-next-line
  }, []);

  const start_date = new Date(watch("start_date"));
  const get_next_day = new Date(start_date);
  get_next_day.setDate(start_date.getDate() + 1);

  const minValue = get_next_day
    ? formatDate(get_next_day)
    : formatDate(new Date());

  const project_managers = users?.filter(
    (user: any) => user?.role === "project_manager"
  );
  return (
    <div className="create-project-form">
      <div className="project_form_items">
        <div className="item">
          <Input
            label="Project Name"
            placeholder="Text"
            name="project_name"
            register={register}
            hasError={errors?.project_name}
            required
          />
        </div>
        <div className="item select_employee">
          <Select
            label="Assign to a project manager"
            register={register}
            hasError={errors?.project_owner}
            name="project_owner"
            placeholder="Assign Project"
            isDisabled={loading}
            isLoading={loading}
            value={project_owner}
            onChange={(e: any) => setProject_owner(e)}
            options={[
              ...project_managers?.map(({ name, id }: any) => ({
                value: id,
                label: name,
              })),
            ]}
          />
          {user?.employee?.role === "admin" && (
            <Button
              text="New project manager"
              icon={assets.icons.add}
              className="add_btn"
              onClick={handleUserModalOpen}
            />
          )}
        </div>
      </div>
      <div className="project_form_items">
        <div className="item">
          <Input
            label="Start Date"
            name="start_date"
            register={register}
            hasError={errors?.start_date}
            type="date"
            required
            className="p-r-1"
          />
        </div>
        <div className="item">
          <Input
            type="date"
            label="End Date"
            name="end_date"
            minValue={minValue}
            register={register}
            hasError={errors?.end_date}
            required
          />
        </div>
      </div>
      <div className="project_form_items">
        <div className="item select_team">
          <Select
            label="Assign to a team"
            register={register}
            hasError={errors?.team}
            name="team"
            isDisabled={loading}
            required={!isEdit}
            isLoading={loading}
            value={team_}
            onChange={(e: any) => setTeam(e)}
            options={[
              ...teams?.map(({ title, id }: any) => {
                return {
                  value: id,
                  label: title,
                };
              }),
            ]}
          />
          {user?.employee?.role === "admin" && (
            <Button
              text="New team"
              icon={assets.icons.add}
              className="add_btn"
              onClick={handleTeamModalOpen}
            />
          )}

          {error && team_ === null && (
            <span className="custom-select-error">Please select a team</span>
          )}
        </div>
        <div className="item">
          <Input
            type="number"
            label="Project Point"
            name="points"
            register={register}
            hasError={errors?.points}
            required
            minValue={1}
          />
        </div>
      </div>
      <div className="project_form_items">
        <div className="item ">
          <Input
            type="number"
            label="Project Cost"
            name="cost"
            register={register}
            hasError={errors?.cost}
            required
            minValue={1}
          />
        </div>
        {repos?.length > 0 && (
          <div className="item_">
            <CustomSelect
              name="repositories"
              label="Select Repositories"
              register={register}
              hasError={errors?.repositories}
              isDisabled={loading}
              value={repositories}
              onChange={setRepositories}
              errorMsg={"Select atleast a repository"}
              options={[
                {
                  label: loading ? "Please wait" : "Pick a Repository",
                  value: "",
                },
                ...repos?.map(({ name, id }: any) => ({
                  value: id,
                  label: name,
                })),
              ]}
            />
          </div>
        )}
      </div>
      <div className="admin-project_btn">
        <Button text="Submit" type="submit" isLoading={loading}  disabled={loading}  isLoadingText={'loading....'} />
      </div>
    </div>
  );
};

export default CreateProjectForm;
