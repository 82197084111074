import CustomModalHeader from "components/ui/Modal/SideModal/CustomModalHeader/CustomModalHeader";
import SideModal from "components/ui/Modal/SideModal/SideModal";
import CreateProjectForm from "./CreateProjectForm/CreateProjectForm";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import API from "utils/api/API";
import { apiUrls, httpVerbs } from "utils/api";
import { getErrorMessage } from "utils/helper";
import toastMessage from "utils/toast";
import { useSelector } from "react-redux";
import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";
import AdminUsersModals from "components/Main/Admin/AdminUsers/AdminUsersModals/AdminUsersModals";
import { MODAL_ACTIONS } from "components/Main/enums";
import AdminTeamsModals from "components/Main/Admin/AdminTeams/AdminTeamsModals/AdminTeamsModals";

const { CREATE_ACTION } = MODAL_ACTIONS;

const CreateProject = (props: {
  onClose: Function;
  handleSuccess: Function;
  isOpen: boolean;
  selectedProject?: any;
  isEdit?: boolean;
}) => {
  const { onClose, handleSuccess, isOpen, isEdit, selectedProject } = props;
  const [loading, setLoading] = useState(false);
  const [openUserModal, setUserModal] = useState(false);
  const [openTeamModal, setTeamModal] = useState(false);
  const [repositories, setRepositories] = useState([]);
  const [repos, setRepos] = useState([]);
  const [users, setUsers] = useState([]);
  const [error, setErrors] = useState(false);
  const [project_owner, setProject_owner] = useState<any>(null);
  const [team_, setTeam] = useState<any>(null);
  const { user } = useSelector((state: any) => state.auth);
  const { employee } = user || {};

  const handleUserModalOpen = () => {
    setUserModal(true);
  };

  const handleUserModalClose = () => {
    setUserModal(false);
  };

  const handleTeamModalOpen = () => {
    setTeamModal(true);
  };

  const handleTeamCodalClose = () => {
    setTeamModal(false);
  };

  const { company, team } = employee || {};

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    reset,
    watch,
  } = useForm();

  const handleClose = () => {
    reset();
    onClose();
  };

  const getRespositories = () => {
    setLoading(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.repositories,
    })
      .then((resp) => {
        setLoading(false);
        setRepos(resp?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getRespositories();
  }, []);

  const createProject = (data: any) => {
   if (team_ === null) {
      setErrors(true);
    } else {
      setLoading(true);
      const filtered_repositories = repositories?.map(
        (repository: any) => repository.value
      );
      API({
        method: httpVerbs?.POST,
        url: apiUrls?.projects,
        data: {
          ...data,
          team: JSON?.parse(team_?.value),
          company: company?.id,
          repositories: filtered_repositories,
          project_owner: project_owner?.value,
        },
      })
        .then((resp) => {
          setLoading(false);
          toastMessage("Project created successfully.");
          handleSuccess();
          reset({
           project_name: '',
            start_date: '',
           end_date: '',
            points: '',
            cost: '',
          })
          setProject_owner({})
          setTeam({})
          handleClose();
        })
        .catch((err) => {
          const errMsg = getErrorMessage(err);
          setLoading(false);
          toastMessage(errMsg, true);
        });
    }
  };

  const updateProject = (data: any) => {
    if (team_ === null) {
      setErrors(true);
    } else {
      setLoading(true);
      const mapped_repositories = repositories?.map(
        (repository: any) => repository.value
      );
      const filtered_repositories = mapped_repositories?.filter(
        (val) => val !== ""
      );
      API({
        method: httpVerbs?.PATCH,
        url: `${apiUrls?.projects}${selectedProject?.id}/`,
        data: {
          ...data,
          team: selectedProject?.team?.id,
          company: company?.id,
          repositories: filtered_repositories,
        },
      })
        .then((resp) => {
          setLoading(false);
          toastMessage("Project updated successfully.");
          handleSuccess();
          reset({
           project_name: '',
            start_date: '',
           end_date: '',
            points: '',
            cost: '',
          })
          setProject_owner({})
          setTeam({})
          handleClose();
        })
        .catch((err) => {
          const errMsg = getErrorMessage(err);
          setLoading(false);
          toastMessage(errMsg, true);
        });
    }
  };

  const onSubmit = (data: any) => {
    if (
      repositories?.length === 0 &&
      Object.keys(errors)?.length &&
      !errors?.repositories
    ) {
      setError("repositories", {
        type: "required",
      });
    } else {
      isEdit ? updateProject(data) : createProject(data);
    }
  };

  useEffect(() => {
    if (selectedProject && isEdit) {
      Object.keys(selectedProject)?.map((tk) => {
        return setValue(tk, selectedProject?.[tk]);
      });
      setValue("team", selectedProject?.team?.id);
    }
    // eslint-disable-next-line
  }, [selectedProject, isEdit]);

  useEffect(() => {
    if (
      repositories?.length === 0 &&
      Object.keys(errors)?.length &&
      !errors?.repositories
    ) {
      setError("repositories", {
        type: "required",
      });
    }
  }, [errors]);

  const getUsers = () => {
    setLoading(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.employee_names,
    })
      .then((resp) => {
        setUsers(resp.data.data);
        setLoading(false);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        toastMessage(errMsg);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      user?.employee?.role === "project_manager" ||
      user?.employee?.role === "admin"
    ) {
      getUsers();
    }
  }, [user?.employee?.role]);

  useEffect(() => {
    if (isEdit && repos) {
      const active_repos = repos.filter((item: any) =>
        selectedProject?.repositories?.find((repo: any) => item.id === repo?.id)
      );
      const new_repos = active_repos?.map((repo: any) => {
        return {
          value: repo?.id,
          label: repo?.name,
        };
      });
      setRepositories(new_repos as []);
    }
  }, [isEdit, repos]);

  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={handleClose}
      title={isEdit ? "Update Project" : "Add Project"}
      // useCustomHeader
      showTitle={true}
      hideButton={true}
      sx={{ width: 500 }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <CustomModalHeader
          onClose={handleClose}
          title={isEdit ? "Update Project" : "Add Project"}
          saveBtnIsLoading={loading}
        /> */}
        <CreateProjectForm
          register={register}
          errors={errors}
          isEdit={isEdit}
          repositories={repositories}
          setRepositories={setRepositories}
          team={team}
          repos={repos}
          watch={watch}
          users={users}
          handleClose={onClose}
          project_owner={project_owner}
          setProject_owner={setProject_owner}
          team_={team_}
          setTeam={setTeam}
          error={error}
          handleUserModalOpen={handleUserModalOpen}
          handleTeamModalOpen={handleTeamModalOpen}
        />
      </form>
      <AdminUsersModals
        onClose={handleUserModalClose}
        modalAction={CREATE_ACTION}
        isOpen={openUserModal}
        handleSuccess={getUsers}
      />

      <AdminTeamsModals
        onClose={handleTeamCodalClose}
        modalAction={CREATE_ACTION}
        isOpen={openTeamModal}
        // handleSuccess={getTeamsData}
      />
    </DefaultModal>
  );
};

export default CreateProject;
