import React from "react";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import toastMessage from "utils/toast";
import { getErrorMessage } from "utils/helper";
import { Box, Button, Typography, useTheme } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import { usePaystackPayment } from "react-paystack";
import { PaystackButton } from "react-paystack";
import { useSelector } from "react-redux";
import "./Card.scss";

const Header = (props: { getAllCards?: any; setLoading: any }) => {
  // Props
  const { getAllCards, setLoading } = props;
  const theme = useTheme();

  // Redux state
  const { user } = useSelector((state: any) => state.auth);

  // Asybc request
  const AddCard = (refrenece: any) => {
    setLoading(true);
    const body = {
      transaction_ref: refrenece,
      company: user?.employee?.company?.id,
    };
    API({
      method: httpVerbs?.POST,
      url: apiUrls?.addCard,
      data: body,
    })
      .then((resp) => {
        getAllCards();
        setLoading(false);
        toastMessage("Card Added Successfully!");
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  const onSuccess = (reference: any) => {
    AddCard(reference);
  };

  const onClose = () => {
    console.log("closed");
  };

  // Config
  const componentProps = {
    email: user?.email,
    amount: 500?.toFixed(2),
    publicKey: "pk_live_b9be070466db99b8a785e50383b95aa0a6872d47",
    // publicKey: "pk_test_6b44fd2bf2c45c1f677ec8b8463a5ecd8fcf0421",
    channels: ["card"],

    metadata: {
      name: `${user?.first_name} ${user?.last_name}`,
    },
    onSuccess: (response: any) => {
      onSuccess(response?.reference);
    },
    onClose: onClose,
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
          display: "block",
        },
      }}
    >
      <Box>
        <Typography
          sx={{ fontWeight: 500, fontSize: "28px", color: "#000000" }}
        >
          Payment Information
        </Typography>
        <Typography
          sx={{ fontWeight: 400, fontSize: "14px", color: "#000000" }}
        >
          Add your payment details. All billable features will be charged to the
          default credit card.{" "}
        </Typography>
      </Box>

      <PaystackButton className="paystack_btn" {...(componentProps as any)}>
        {" "}
        <Button
          variant="contained"
          sx={{
            textTransform: "inherit",
            backgroundColor: "#008FE3",
            fontSize: "14px",
            width: "250px",
            py: 1.5,
            [theme.breakpoints.down("sm")]: {
              width: "300px",
            },
          }}
          startIcon={<AddCircleOutlineIcon />}
        >
          Add New Credit Card
        </Button>
      </PaystackButton>
    </Box>
  );
};

export default Header;
