import assets from "assets";
import Button from "components/ui/Button";
import { ReactSVG } from "react-svg";

const CustomModalHeader = (props: {
  title?: string;
  onClose?: Function;
  subTitle?: string;
  saveBtnIsLoading?: boolean;
  noButton?: boolean;
  disabled?: any;
  noBack?: any;
}) => {
  const {
    title,
    onClose,
    subTitle,
    saveBtnIsLoading,
    noButton,
    disabled,
    noBack,
  } = props;
  return (
    <div className="side-modal__heading custom">
      {!noBack && (
        <div className="side-modal__heading__content">
          <Button
            text="Back"
            className="back__button"
            icon={assets.icons.arrowLeft}
            onClick={() => onClose?.()}
          />
          {/* <span onClick={() => onClose?.()}>
            <ReactSVG src={assets.icons.cancel} />
          </span> */}
        </div>
      )}
      {noBack && (
        <div style={{ float: "right" }}>
          <span onClick={() => onClose?.()}>
            <ReactSVG src={assets.icons.cancel} />
          </span>
        </div>
      )}
      <div className="side-modal__heading__content">
        <h3>{title}</h3>
        {!noButton && (
          <Button
            text="Submit"
            icon={assets.icons.save}
            type="submit"
            isLoading={saveBtnIsLoading}
            isLoadingText={'loading...'}
            disabled={disabled ? disabled : false}
          />
        )}
      </div>
      {subTitle && <p>{subTitle}</p>}
    </div>
  );
};

export default CustomModalHeader;
