import assets from "assets";
import { ReactSVG } from "react-svg";
import Select from "components/ui/Select";

import "./DashboardCards.scss";
import { MetricDashboardPropsTypes } from "../DashboardD.types";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { useEffect, useState } from "react";

const DashboardCards = (props: MetricDashboardPropsTypes) => {
  const {
    total_employees,
    total_team,
    total_project,
    kpi_score,
    total_cost,
    projects,
    project,
    setProject,
    loading,
    setLoading,
    effective_group,
  } = props;
  const [value, setValue] = useState(0);

  const getMTTR = () => {
    setLoading(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.getMttr(project?.value || project),
    })
      .then((resp) => {
        setLoading(false);
        const { data } = resp?.data || {};
        setValue(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (project) {
      getMTTR();
    }
  }, [project]);


  return (
    <div className="dashboard-cards">
      <section className="dashcard_content">
        <div className="card_icon">
          <ReactSVG src={assets.icons.EmployeeIcon} className="icon" />
        </div>
        <h6>Total Employee</h6>
        <h2>{total_employees}</h2>
      </section>
      <section className="dashcard_content">
        <div className="card_icon">
          <ReactSVG src={assets.icons.TeamsIcon} className="icon" />
        </div>
        <h6>Total Team</h6>
        <h2>{total_team}</h2>
      </section>

      <section className="dashcard_content">
        <div className="card_icon">
          <ReactSVG src={assets.icons.KpiIcon} className="icon" />
        </div>
        <h6>Total KPIs</h6>
        <h2>{kpi_score}</h2>
      </section>

      <section className="dashcard_content">
        <div className="card_icon">
          <ReactSVG src={assets.icons.ProjectIcon_} className="icon" />
        </div>
        <h6>Total Projects</h6>
        <h2>{total_project}</h2>
      </section>
      <section className="dashcard_content">
        <div className="card_icon">
          <ReactSVG src={assets.icons.CostIcon} className="icon" />
        </div>
        <h6>Total Salary</h6>
        <h2>&#8358; {total_cost?.toLocaleString()}</h2>
      </section>
      {/* <section className="best_metric">
        <div className="card_icon">
          <ReactSVG src={assets.icons.Person} className="icon" />
        </div>
        <h6>Most Effective Team Member</h6>
        <h2>{effective_group?.most ? effective_group?.most[1] : "None"}</h2>
      </section>
      <section className="best_metric">
        <div className="card_icon">
          <ReactSVG src={assets.icons.Person} className="icon" />
        </div>
        <h6>Least Effective Team Member</h6>
        <h2>{effective_group?.least ? effective_group?.least[1] : "None"}</h2>
        <span></span>
      </section> */}
      <section className="best_metric">
        <div className="metric_head">
          <h6 title="Mean Time To Recover">MTTR</h6>
          <span className="tooltiptext">Mean Time To Recover</span>
          <div className="d-flex p-y-1 create-pc-modal-form__inputs">
            <Select
              // label="Select Project"
              value={project}
              onChange={(e: any) => setProject(e)}
              isLoading={loading}
              options={[
                ...projects?.map(({ project_name, id }: any) => {
                  return {
                    value: JSON.stringify(id),
                    label: project_name,
                  };
                }),
              ]}
            />
          </div>
        </div>

        <h2>{value === 0 ? value : `${value} days`}</h2>
      </section>
    </div>
  );
};

export default DashboardCards;
