import assets from "assets";

import LayoutSidebarItem from "./LayoutSidebarItem/LayoutSidebarItem";
import { useLocation } from "react-router-dom";

import "./LayoutSidebar.scss";
import Drawer from "react-modern-drawer";
import { useDispatch, useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { useEffect, useState } from "react";
import PremiumSwitchModal from "components/ui/Modals/PremiumSwitch";
import AddCard from "../modals/AddCard";
import { getErrorMessage } from "utils/helper";
import toastMessage from "utils/toast";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { updateAuthInfo } from "store/auth/actions";

const sidebarItemsAdmin = [
  {
    title: "Dashboard",
    route: "/dashboard",
    icon: assets.icons.dashboard,
  },
  {
    title: "Team Set-up",
    route: "/admin/users/",
    icon: assets.icons.admin,
  },
  {
    title: "Project Set-up",
    route: "/project/overview",
    icon: assets.icons.project,
  },
  // {
  //   title: "Metric",
  //   route: "/metrics/overview",
  //   icon: assets.icons.metrics,
  // },
  {
    title: "Admin Settings",
    route: "/settings/user/",
    icon: assets.icons.settings,
  },
  {
    title: "Help & support",
    route: "/help-support/",
    icon: assets.icons.help,
  },
];

const sidebarItemsEmployee = [
  {
    title: "Projects",
    route: "/project/overview",
    icon: assets.icons.project,
  },
  {
    title: "Settings",
    route: "/settings/user/",
    icon: assets.icons.settings,
  },
  {
    title: "Help & support",
    route: "/help-support/",
    icon: assets.icons.help,
  },
];

const LayoutSidebarContent = (props: {
  firstSubPath: string;
  closeModal?: Function;
}) => {
  const { firstSubPath, closeModal } = props;
  const { user } = useSelector((state: any) => state.auth);
  return (
    <>
      {(user?.employee?.role !== "admin"
        ? sidebarItemsEmployee
        : sidebarItemsAdmin
      )?.map((item, key) => {
        return user?.employee?.role !== "admin" &&
          (item?.title === "Dashboard" ||
            item?.title === "Team Set-up") ? null : (
          <LayoutSidebarItem
            {...item}
            key={key}
            isOpen={item?.route?.includes(firstSubPath)}
            closeModal={closeModal}
          />
        );
      })}
    </>
  );
};

const LayoutSidebar = (props: {
  showSidebar: boolean;
  closeModal: Function;
}) => {
  const { showSidebar, closeModal } = props;
  const [switchPremium, setSwitchPremium] = useState(false);
  const [switchBack, setSwitchBack] = useState(false);
  const [allCards, setAllCards] = useState([]);
  const { pathname } = useLocation();
  const { user } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();

  const path = pathname.substring(1);

  const firstSubPath = path.split("/")[0].toString();

  const updateAiUsage = () => {
    if (allCards?.length === 0) {
      setSwitchPremium(true);
    } else if (user?.employee?.company?.premium_ai) {
      setSwitchBack(true);
    } else {
      API({
        method: httpVerbs?.PATCH,
        url: apiUrls?.updatePremiumAi(user?.employee?.company?.id),
        data: {
          premium_ai: user?.employee?.company?.premium_ai ? false : true,
        },
      })
        .then((resp) => {
          const updatedUser = { ...user };
          updatedUser.employee.company = {
            ...updatedUser.employee.company,
            premium_ai: user?.employee?.company?.premium_ai ? false : true,
          };
          dispatch<any>(updateAuthInfo({ user: updatedUser }));

          toastMessage("You are now on premium AI");
        })
        .catch((err) => {
          const errMsg = getErrorMessage(err);
          toastMessage(errMsg, true);
        });
    }
  };

  const getAllCards = () => {
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.getAllCards,
    })
      .then((resp) => {
        setAllCards(resp?.data?.data);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        toastMessage(errMsg, true);
      });
  };

  useEffect(() => {
    if(user?.user_type === 'admin')
    getAllCards();
  }, [user]);

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 100,
    height: 40,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(60px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#008ee2" : "#008ee2",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#008ee2",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 36,
      height: 34,
    },
    "& .MuiSwitch-track": {
      borderRadius: 42 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  return (
    <>
      <section className="layout-sidebar">
        <LayoutSidebarContent {...{ firstSubPath }} />
        {user?.employee?.role === "admin" && (
          <div className="switch">
            <p className="switch_label">Switch to Premium AI</p>
            <div className="switch_item">
              <IOSSwitch
                checked={user?.employee?.company?.premium_ai}
                onChange={() => setSwitchPremium(true)}
              />
            </div>
          </div>
        )}
      </section>

      <Drawer
        open={showSidebar}
        style={{ marginTop: "70px" }}
        overlayOpacity={0}
        enableOverlay={false}
        onClose={() => console.log("he")}
        className="layout-mobile-sidebar"
        direction="left"
      >
        <section>
          <LayoutSidebarContent {...{ firstSubPath, closeModal }} />
          {user?.employee?.role === "admin" && (
            <div className="switch_">
              <p className="switch_label">Switch to Premium AI</p>
              <div className="switch_item">
                <IOSSwitch
                  checked={user?.employee?.company?.premium_ai}
                  onChange={updateAiUsage}
                />
              </div>
            </div>
          )}
        </section>
      </Drawer>
      <PremiumSwitchModal
        isOpen={switchBack}
        onClose={() => setSwitchBack(false)}
      />
      <AddCard
        isOpen={switchPremium}
        onClose={() => setSwitchPremium(false)}
        getAllCards={getAllCards}
      />
    </>
  );
};

export default LayoutSidebar;
