import { Typography } from "@mui/material";
import AuthLayout from "../AuthLayout/AuthLayout";
// import AuthButtonActions from "../common/AuthButtonActions/AuthButtonActions";
import AuthFooter from "../common/AuthFooter/AuthFooter";
import AuthHeader from "../common/AuthHeader/AuthHeader";
import VeirfyForm from "./VerifyEmail/VerifyForm";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import API from "utils/api/API";
import { apiUrls, httpVerbs } from "utils/api";
import toastMessage from "utils/toast";
import { getErrorMessage } from "utils/helper";

const Login = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (id) {
  //     const verifyEmail = () => {
  //       setLoading(true);
  //       API({
  //         method: httpVerbs?.POST,
  //         url: apiUrls?.verifyEmail,
  //         data: {
  //           id,
  //         },
  //       })
  //         .then((resp) => {
  //           setLoading(false);
  //         })
  //         .catch((err) => {
  //           const errMsg = getErrorMessage(err);
  //           setLoading(false);
  //           toastMessage(errMsg, true);
  //         });
  //     };
  //     verifyEmail();
  //   }
  // }, [id]);
  return (
    <AuthLayout>
      <AuthHeader
        title={
          <>
            <Typography
              sx={{
                fontSize: "36px",
                fontWeight: "700",
                textAlign: "center",
                color: "#008ee2",
              }}
            >
              Verify Email
            </Typography>
          </>
        }
        subTitle={
          <>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                textAlign: "center",
                color: "#666e80",
              }}
            >
              Please enter the verification code sent to your email address to continue
            </Typography>
          </>
        }
      />
      <VeirfyForm />
    </AuthLayout>
  );
};

export default Login;
