// export const baseUrl = "https://sprintstar-be-jvg7j.ondigitalocean.app/api/";
export const baseUrl = "https://api.sprintstar.io/api/";
const userMgt = "user_mgt/";
const integration = "integration/";
const project = "project/";
const people = "people/";
const kpi = "kpi/";

export const apiUrls = {
  login: `${baseUrl}${userMgt}login/`,
  verifyEmail: `${baseUrl}${userMgt}verify-email/`,
  register: `${baseUrl}${userMgt}register/`,
  sendPasswordOtp: `${baseUrl}${userMgt}forgot-password/`,
  validatePasswordOtp: `${baseUrl}${userMgt}validate-otp/`,
  setNewPassword: (userId: any) =>
    `${baseUrl}${userMgt}set-password/${userId}/`,
  users: `${baseUrl}${userMgt}users/`,
  employees: `${baseUrl}${userMgt}employee/`,
  employee_names: `${baseUrl}${userMgt}employee-names/`,
  confirmEmail: `${baseUrl}${userMgt}confirm-email/`,
  updatePassword: `${baseUrl}${userMgt}update-password/`,
  updateBasicProfile: (id: number) => `${baseUrl}${userMgt}users/${id}/`,
  updateCompany: (id: number) =>
    `${baseUrl}${userMgt}company-onboarding/${id}/`,
  faq: `${baseUrl}${userMgt}faq/`,
  createCompany: `${baseUrl}${userMgt}company-onboarding/`,
  mfaSetup: `${baseUrl}${userMgt}setup-mfa/`,
  mfaCompleteSetup: `${baseUrl}${userMgt}complete-mfa-setup/`,
  mfaCheck: `${baseUrl}${userMgt}mfa-check/`,

  setup_github: `${baseUrl}${integration}complete-github-installation/`,
  setup_heroku: `${baseUrl}${integration}integrate-heroku/`,
  setup_digitalocean: `${baseUrl}${integration}integrate-digitalocean/`,
  setup_gitlab: `${baseUrl}${integration}gitlab-hook/`,
  get_integrations_platforms: `${baseUrl}${integration}integrations/`,
  connectUserIntegration: `${baseUrl}${integration}user-integrations/`,
  connectCodeUserIntegration: `${baseUrl}${integration}github-installation/`,
  uploadIntegrationFile: `${baseUrl}${integration}integration-file-upload/`,
  deleteUserIntegration: (id: string) =>
    `${baseUrl}${integration}user-integrations/${id}/`,
  deleteCodeIntegration: (id: string) =>
    `${baseUrl}${integration}github-installation/${id}/`,
  update_github: (id: string) =>
    `${baseUrl}${integration}github-installation/${id}/`,
  addCard: `${baseUrl}${integration}add-card/`,
  getAllCards: `${baseUrl}${integration}cards/`,
  updateCard: (id: number) => `${baseUrl}${integration}cards/${id}/`,
  deleteCard: (id: number) => `${baseUrl}${integration}cards/${id}/`,

  employee: `${baseUrl}${userMgt}employee/`,
  deleteUser: `${baseUrl}${userMgt}users/`,
  teams: `${baseUrl}${people}team/`,
  teams_name: `${baseUrl}${people}team-names/`,
  employeesUpload: `${baseUrl}${people}employee-upload/`,

  adminStats: `${baseUrl}${people}admin-numbers`,

  projects: `${baseUrl}${project}project/`,
  projectIssues: `${baseUrl}${integration}issue/`,
  updateProjectIssue: (id: string) => `${baseUrl}${integration}issue/${id}/`,
  projectTasks: `${baseUrl}${project}task/`,
  taskBlockers: `${baseUrl}${project}blocker/`,
  deleteTaskBlocker: (id: string) => `${baseUrl}${project}blocker/${id}/`,
  projectConfiguration: `${baseUrl}${project}configuration/`,
  projectTestPlans: `${baseUrl}${project}plan/`,
  projectTestSuites: `${baseUrl}${project}suites/`,
  projectSuiteCases: `${baseUrl}${project}cases/`,
  projectMilestone: `${baseUrl}${project}milestone/`,
  projectCalendar: `${baseUrl}${project}calendar/`,

  // metrics
  teamMetrics: `${baseUrl}${people}team-metrics/`,
  getMttr: (id: any) => `${baseUrl}${people}get-mttr/${id}`,
  getCodeMetrics: (id: any) => `${baseUrl}${people}git-metrics-chart/${id}`,
  getTotalCosts: `${baseUrl}${integration}get-cost-chart`,
  getTaskCompleted: (id: any) =>
    `${baseUrl}${people}task-completion-chart/${id}`,
  dashboardMetrics: `${baseUrl}${people}admin-dashboard/`,
  // /api/people/team-detail/?team=team_id
  teamDetails: `${baseUrl}${people}team-details/`,
  apiTest: `${baseUrl}${people}team-detail/`,
  integrationRisks: `${baseUrl}${integration}risks/`,
  // /api/integration/risks/
  teamSummary: (id: any) => `${baseUrl}${people}team-summary/${id}/`,
  teamKpi: `${baseUrl}${kpi}team-kpi/`,
  teamManualKpi: `${baseUrl}${kpi}team-manual-kpi/`,
  personalManualKpi: `${baseUrl}${people}personal-manual-kpi/`,
  teamKpiTable: `${baseUrl}${people}team-kpi-table/`, //'should be used under admin kpi when they click on a team there
  employeeKpiTable: `${baseUrl}${people}employee-kpi-table`, //'should be used under admin kpi when they click on a team there
  individualKpi: `${baseUrl}${people}employee-kpi-dashboard/`,
  personalKpi: `${baseUrl}${people}personal-kpi/`,
  allPayroll: `${baseUrl}${people}payroll/`,
  teamKpiDashboard: `${baseUrl}${people}team-kpi-dashboard/`,
  repositories: `${baseUrl}${integration}repository/`,
  clocking: `${baseUrl}${people}clocking/`,
  updatePremiumAi: (id: number) => `${baseUrl}${people}company/${id}/`,

  // Chat Ai
  chatAi: `${baseUrl}${integration}ai-chat/v2/`,
};

export const httpVerbs = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const dataQueryStatuses = {
  IDLE: "IDLE",
  DATAMODE: "DATAMODE",
  NULLMODE: "NULLMODE",
  ERROR: "ERROR",
  LOADING: "LOADING",
  SUCCESS: "SUCESS",
};
