import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import "./TwoFa.scss";
import Input from "components/ui/Input";
// import QRCode from "assets/general/QRCode.svg";
import QRCode from "qrcode.react";

type Props = {
  values: any;
  register: Function;
  errors: any;
  loading: boolean
};

const Authenticator = ({ values, register, errors, loading }: Props) => {
  const { user } = useSelector((state: any) => state.auth);


  return (
    <Box sx={{ width: "100%" }} className="email_authentication">
      <h4>Scan QR code</h4>
      <p>
        You will need a <span> Google Authenticator</span>
        {" "}
        to complete this process
      </p>
      <h5 className="scan_code">Scan the QR code below</h5>
      <Box
        sx={{
          m: 3,
          p: 1,
          width: "320px",
          border: `1.33px solid primary`,
          borderRadius: "5.32px",
        }}
      >
        <QRCode value={values?.url} size={300} />
      </Box>
      <div>
        <Box sx={{ width: "100%", mt: 3 }}>
          <p>Enter the 6-digits authentication code generated by your app</p>

          <div>
            <input
              {...register?.("code", { required: true })}
              placeholder="Enter Code"
              type="text"
              className="input"
            />
            {errors?.code && (
              <Typography color="error">Code is required.</Typography>
            )}
          </div>
          <Button
            variant="contained"
            type="submit"
            sx={{
              mt: 1.5,
              px: 6,
              backgroundColor: "#008EE2",
              py: 1.5,
              float: "right",
              textTransform: "inherit",
              "&:hover": {
                backgroundColor: "#008EE2",
              },
            }}
            disabled={loading}
          >
            Verify
          </Button>
        </Box>
      </div>
    </Box>
  );
};

export default Authenticator;
