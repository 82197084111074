import React, { useState } from "react";
import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";
import "../LayoutHeader/LayoutHeader.scss";
import assets from "assets";
import "components/ui/Modal/DefaultModal/DefaultModal.scss";
import { PaystackButton } from "react-paystack";
import { useDispatch, useSelector } from "react-redux";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import toastMessage from "utils/toast";
import { getErrorMessage } from "utils/helper";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Button, CircularProgress } from "@mui/material";
import { updateAuthInfo } from "store/auth/actions";

const AddCard = (props: {
  onClose: Function;
  isOpen: boolean;
  // setCardModal: any;
  getAllCards: any;
}) => {
  const { onClose, isOpen, getAllCards } = props;
  const { user } = useSelector((state: any) => state.auth);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onSuccess = (reference: any) => {
    AddCard(reference);
  };

  const handleClose = () => {
    console.log("closed");
  };

  const updateAiUsage = () => {
    API({
      method: httpVerbs?.PATCH,
      url: apiUrls?.updatePremiumAi(user?.employee?.company?.id),
      data: { premium_ai: user?.employee?.company?.premium_ai ? false : true },
    })
      .then((resp) => {
        setLoading(false);
        toastMessage("Card Added Successfully!");
        const updatedUser = { ...user };
        updatedUser.employee.company = {
          ...updatedUser.employee.company,
          premium_ai: user?.employee?.company?.premium_ai ? false : true,
        };
        dispatch<any>(updateAuthInfo({ user: updatedUser }));
        onClose();
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        toastMessage(errMsg, true);
      });
  };
  // Asybc request
  const AddCard = (refrenece: any) => {
    setLoading(true);
    const body = {
      transaction_ref: refrenece,
      company: user?.employee?.company?.id,
    };
    API({
      method: httpVerbs?.POST,
      url: apiUrls?.addCard,
      data: body,
    })
      .then((resp) => {
        getAllCards();
        updateAiUsage();
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  // Config
  const componentProps = {
    email: user?.email,
    amount: 500?.toFixed(2),
    publicKey: "pk_live_b9be070466db99b8a785e50383b95aa0a6872d47",
    // publicKey: "pk_test_6b44fd2bf2c45c1f677ec8b8463a5ecd8fcf0421",
    channels: ["card"],

    metadata: {
      name: `${user?.first_name} ${user?.last_name}`,
    },
    onSuccess: (response: any) => {
      onSuccess(response?.reference);
    },
    onClose: handleClose,
  };
  return (
    <>
      <DefaultModal isOpen={isOpen} onClose={onClose} sx={{ width: 500 }}>
        <div>
          <div className="add_card">
            <img
              src={assets.icons.CreditCard}
              alt="aiLogo"
              width="100"
              height="100"
            />
            <>
              <h4>Enhance your experience!</h4>
              <p>Add your card to have unlimited access to our AI feature.</p>
              <p>It's quick, easy, and secure!</p>
            </>
          </div>
          <PaystackButton className="paystack_btn" {...(componentProps as any)}>
            <Button
              variant="contained"
              sx={{
                textTransform: "inherit",
                backgroundColor: "#008FE3",
                fontSize: "14px",
                width: "430px",
                py: 1.5,
                "&:hover": {
                  backgroundColor: "#008FE3",
                },
              }}
              startIcon={<AddCircleOutlineIcon />}
            >
              {loading ? (
                <CircularProgress sx={{ color: "#FFFFFF" }} />
              ) : (
                "Add Card"
              )}
            </Button>
          </PaystackButton>
        </div>
      </DefaultModal>
    </>
  );
};

export default AddCard;
