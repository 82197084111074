import Select from "react-select";
import "../Select.scss";

interface SelectTypes {
  selectClass?: string;
  labelClass?: string;
  label?: string;
  placeholder?: string;
  onChange?: Function;
  value?: any;
  id?: string;
  name?: string;
  invertStyle?: boolean;
  hasError?: boolean;
  errorMsg?: string;
  className?: string;
  register?: Function;
  required?: boolean;
  type?: string;
  optionClass?: string;
  options?: Array<{
    value: any;
    label: any;
  }>;
  isDisabled?: Boolean;
  isMultiple?: boolean;
  defaultValue?: any;
  isLoading?: any
}

const CustomSelect = (props: SelectTypes) => {
  const {
    selectClass,
    labelClass,
    onChange,
    label,
    value,
    id,
    name,
    invertStyle,
    errorMsg,
    hasError,
    className,
    options,
    defaultValue,
    isLoading,
  } = props;

  return (
    <>
      <div className={`custom-select ${className}`}>
        <label className={`label ${labelClass}`}>{label}</label>
        <Select
          name={name}
          id={id}
          className={`select_ ${selectClass} ${
            invertStyle ? "input-invert" : ""
          } ${hasError ? "custom-select-error-input" : ""}`}
          isMulti
          options={options}
          onChange={(e: any) => onChange?.(e)}
          value={value}
          defaultValue={defaultValue}
          menuPortalTarget={document.body}
          isLoading={isLoading}
          styles={{
            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
            control: (base: any) => ({
              ...base,
              // height: "55px",
              "min-height": "55px",
              border: '1px solid #e9e9f0'
            }),
          }}
          //   {...register?.(name, { required })}
        />
        {hasError && (
          <span className="custom-select-error">
            {errorMsg ? errorMsg : `${label} is required.`}
          </span>
        )}
      </div>
    </>
  );
};

export default CustomSelect;
