import Input from "components/ui/Input";
import Select from "components/ui/Select";
import { useEffect } from "react";
import CustomSelect from "components/ui/Select/CustomSelect";
import assets from "assets";
import { Button } from "components/ui";
import "../TeamStyle.scss";
import { useSelector } from "react-redux";

const CreateTeamForm = (props: {
  register: Function;
  errors: any;
  isEdit?: boolean;
  employee: any;
  setEmployee: Function;
  watch: Function;
  users?: any;
  loading?: any;
  handleClose: Function;
  error: boolean;
  lead: any;
  setLead: any;
  handleUserModalOpen: any;
  contentLoading: boolean;
}) => {
  const {
    register,
    errors,
    isEdit,
    employee,
    setEmployee,
    users,
    loading,
    handleClose,
    error,
    lead,
    setLead,
    handleUserModalOpen,
    contentLoading,
  } = props;
  const { user } = useSelector((state: any) => state.auth);
  useEffect(() => {
    if (lead && users) {
      const updated_employees = [...employee];
      const find_lead = users?.find(
        (user: any) => user?.id === lead?.value
      );
      const modified_employees = updated_employees?.map(
        (employee) => employee.value
      );
      if (!modified_employees?.includes(Number(lead))) {
        updated_employees.push({
          label: (find_lead as any)?.name as any,
          value: (find_lead as any)?.id,
        });
      }
      if (!isEdit) {
        setEmployee(updated_employees);
      }
    }
  }, [lead, users]);
  const filtered_users = users?.filter(
    (user: any) => user?.role === "team_lead"
  );
  const filtered_employees = users?.filter(
    (user: any) =>
      user?.role !== "admin"
  );
  return (
    <div className="create-team-form">
      <div className="p-y-1">
        <Input
          label="Name of Team"
          placeholder="Text"
          name="title"
          register={register}
          hasError={errors?.title}
          required
        />
      </div>
      <div className="p-y-1 select_employee">
        <Select
          label="Team Lead"
          name="lead"
          value={lead}
          onChange={(e: any) => setLead(e)}
          isDisabled={loading}
          required={!isEdit}
          isLoading={contentLoading}
          options={[
            ...filtered_users?.map(({ id, name }: any) => {
              return {
                label: name,
                value: id,
              };
            }),
          ]}
        />
        {user?.employee?.role === "admin" && (
          <Button
            text="Add employee"
            icon={assets.icons.add}
            onClick={handleUserModalOpen}
            className="add_btn"
          />
        )}
        {error && lead === null && (
          <span className="custom-select-error">Please select a team lead</span>
        )}
      </div>

      <div className="p-y-1">
        <CustomSelect
          name="employee"
          label="Employees"
          register={register}
          hasError={errors?.employee}
          isDisabled={loading}
          value={employee}
          onChange={setEmployee}
          isLoading={contentLoading}
          errorMsg={"Select atleast an employee"}
          options={[
            ...filtered_employees?.map(({ name, id }: any) => ({
              value: id,
              label: name,
            })),
          ]}
          required={true}
        />
        {error && employee?.length < 2 && (
          <span className="custom-select-error">
            Please select atleast one employee
          </span>
        )}
      </div>
      <div className="admin-team_btn">
        <Button
          text="Cancel"
          className="cancel"
          onClick={() => {
            handleClose();
          }}
        />
        <Button
          text="Submit"
          type="submit"
          className="submit"
          isLoading={loading}
          disabled={loading}
          isLoadingText={'loading....'}
        />
      </div>
    </div>
  );
};

export default CreateTeamForm;
