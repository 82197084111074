import { Box, Button, Typography } from "@mui/material";
import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";
import { useState } from "react";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import toastMessage from "utils/toast";

const DeleteUserModal = (props: {
  handleSuccess?: Function;
  onClose: Function;
  selectedUser: any;
  isOpen: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const { handleSuccess, onClose, selectedUser, isOpen } = props;

  const deleteTeam = () => {
    setLoading(true);
    API({
      method: httpVerbs?.DELETE,
      url: `${apiUrls?.deleteUser}${selectedUser?.user}/`,
    })
      .then((resp) => {
        setLoading(false);
        toastMessage("User deleted successfully.");
        handleSuccess?.();
        onClose();
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };
  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onClose}
      title="Delete this Employee?"
      sx={{ width: 450, height: '280px', my: 0, py: 0 }}
    >
      <Typography sx={{ textAlign: "center", mt: 2 }}>
        Are you sure you want to delete employee{" "}
        <b>{selectedUser?.full_name}</b>?
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 6,
          background: '#F8FAFC',
          py: 3,
          mx: '-2rem',
          mb: '-3rem',
        }}
      >
        <Button
          variant="outlined"
          sx={{ textTransform: "inherit", color: "#008ee2" }}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            ml: 2,
            width: loading ? "130px" : "100px",
            textTransform: "inherit",
            backgroundColor: "#008ee2",
            "&:hover": {
              backgroundColor: "#008ee2",
            },
          }}
          onClick={() => deleteTeam()}
        >
          {loading ? "Please wait..." : "Yes"}
        </Button>
      </Box>
    </DefaultModal>
  );
};

export default DeleteUserModal;
