import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";
import { useState } from "react";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import {
  getErrorMessage,
  getGitlabUrl,
  getGitHubUrl,
  getHerokuUrl,
} from "utils/helper";
import toastMessage from "utils/toast";
import { useForm } from "react-hook-form";
import IntegrationForm from "./IntegrationForm";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const ConnectIntegration = (props: {
  onClose: Function;
  isOpen: boolean;
  integration: any;
  handleSuccess: any;
}) => {
  const { onClose, isOpen, integration, handleSuccess } = props;
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [service_account_credential, setServiceAccount] = useState("");
  const from = ((location.state as any)?.from.pathname as string) || "/profile";

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { user } = useSelector((state: any) => state.auth);
  const { employee } = user || {};

  const { company } = employee || {};

  const { id: companyId } = company || {};
  const onSubmit = (data: any) => {
    setLoading(true);

    const find_file_field = integration?.fields?.find(
      (field: any) => field?.field_type === "file"
    );

    const fields = Object.keys(data)
      ?.filter((item) => item !== "account_name")
      ?.map((field) => {
        return {
          [field]: data[field],
        };
      });
    const new_fields = Object.assign({}, ...fields);
    const fields_ =
      find_file_field && find_file_field.field_name
        ? {
            ...new_fields,
            [find_file_field && find_file_field.field_name]:
              service_account_credential,
          }
        : new_fields;
    API({
      method: httpVerbs?.POST,
      url: apiUrls?.connectUserIntegration,
      data: {
        name: data?.account_name,
        integration: integration?.id,
        company: companyId,
        fields: fields_,
      },
    })
      .then((resp) => {
        toastMessage(`${integration?.name} Successfully Integrated!`);
        handleSuccess();
        onClose();
        setLoading(false);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };
  const submitForm = (values: any) => {
    if (integration?.type === "oauth") {
      if (integration.name === "Gitlab") {
        localStorage.setItem("integration_name", integration.name);
        localStorage.setItem("account_name", values?.account_name);
        window.location.href = integration?.connection_url;
      } else if (integration.name === "Github") {
        localStorage.setItem("integration_name", integration.name);
        localStorage.setItem("account_name", values?.account_name);
        window.location.href = integration?.connection_url;
      } else if (integration.name === "DigitalOcean") {
        localStorage.setItem("integration_name", integration.name);
        localStorage.setItem("account_name", values?.account_name);
        window.location.href = integration?.connection_url;
      } else {
        localStorage.setItem("integration_name", integration.name);
        localStorage.setItem("account_name", values?.account_name);
        window.location.href = integration?.connection_url;
      }
    } else {
      onSubmit(values);
    }
  };

  return (
    <>
      <DefaultModal
        isOpen={isOpen}
        onClose={onClose}
        title={`Connect your account to ${integration?.name}`}
        buttonText="Connect"
        isLoading={loading}
        hideButton={true}
        showCloseIcon
        center
        sx={{ width: 500 }}
      >
        <form onSubmit={handleSubmit(submitForm)}>
          <IntegrationForm
            register={register}
            errors={errors}
            loading={loading}
            integration={integration}
            setServiceAccount={setServiceAccount}
          />
        </form>
      </DefaultModal>
    </>
  );
};

export default ConnectIntegration;
