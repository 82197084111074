import CustomModalHeader from "components/ui/Modal/SideModal/CustomModalHeader/CustomModalHeader";
import CreateTeamForm from "./CreateTeamForm/CreateTeamForm";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toastMessage from "utils/toast";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";
import AdminUsersModals from "components/Main/Admin/AdminUsers/AdminUsersModals/AdminUsersModals";
import { MODAL_ACTIONS } from "components/Main/enums";

const { CREATE_ACTION } = MODAL_ACTIONS;

const CreateTeamModal = (props: {
  onClose: Function;
  selectedTeam?: any;
  handleSuccess?: Function;
  isOpen: boolean;
  isEdit?: boolean;
}) => {
  const { onClose, isOpen, selectedTeam, isEdit, handleSuccess } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm();
  const { user } = useSelector((state: any) => state.auth);
  const { employee } = user || {};

  const { company } = employee || {};

  const { id: companyId } = company || {};

  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const [openUserModal, setUserModal] = useState(false);
  const [employees, setEmployee] = useState([]);
  const [lead, setLead] = useState<any>(null);
  const [error, setError] = useState(false);
  const [users, setUsers] = useState([]);

  const handleUserModalOpen = () => {
    setUserModal(true);
  };
  const handleUserModalClose = () => {
    setUserModal(false);
  };

  const createTeam = (data: any) => {
    if (employees?.length < 2) {
      setError(true);
    } else if (lead == null) {
      setError(true);
    } else {
      setLoading(true);
      const filtered_employees = employees?.map((employ: any) => employ?.value);
      API({
        method: httpVerbs?.POST,
        url: apiUrls?.teams,
        data: {
          ...data,
          company: companyId,
          lead: lead?.value,
          employees: filtered_employees,
        },
      })
        .then((resp) => {
          setLoading(false);
          toastMessage("Team created successfully.");
          handleSuccess?.();
          reset({
            title: ''
          });
          setLead({})
          setEmployee([])
          onClose();
        })
        .catch((err) => {
          const errMsg = getErrorMessage(err);
          setLoading(false);
          toastMessage(errMsg, true);
        });
    }
  };

  const getUsers = () => {
    setContentLoading(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.employee_names,
    })
      .then((resp) => {
        setUsers(resp.data.data);
        setContentLoading(false);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        toastMessage(errMsg);
        setContentLoading(false);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const updateTeam = (data: any) => {
    if (employees?.length < 2) {
      setError(true);
    } else if (lead == null) {
      setError(true);
    } else {
      const filtered_employees = employees?.map((employ: any) => employ?.value);
      setLoading(true);
      API({
        method: httpVerbs?.PATCH,
        url: `${apiUrls?.teams}${selectedTeam?.id}/`,
        data: {
          ...data,
          company: companyId,
          lead: lead?.value || undefined,
          employees: filtered_employees,
        },
      })
        .then((resp) => {
          setLoading(false);
          toastMessage("Team updated successfully.");
          handleSuccess?.();
          reset({
            title: ''
          });
          setLead({})
          setEmployee([])
          onClose();
        })
        .catch((err) => {
          const errMsg = getErrorMessage(err);
          setLoading(false);
          toastMessage(errMsg, true);
        });
    }
  };

  const onSubmit = (data: any) => {
    isEdit ? updateTeam(data) : createTeam(data);
  };

  const handleClose = () => {
    reset();
    onClose();
    setLead(null);
    setEmployee([]);
  };

  useEffect(() => {
    if (selectedTeam && Object?.keys(selectedTeam)?.length > 0 && users) {
      setValue("title", selectedTeam?.title);
      const find_lead = users?.find(
        (employee: any) => selectedTeam?.lead === employee?.id
      ) as any;
      setLead({
        label: find_lead?.name,
        value: find_lead?.id,
      });

      const filtered_employees = users?.filter((employee: any) =>
        selectedTeam?.employees?.includes(employee?.id)
      );
      const modified_employees = filtered_employees?.map((employ: any) => {
        return {
          label: employ?.name,
          value: employ?.id,
        };
      });
      setEmployee(modified_employees as any);
    }
    // eslint-disable-next-line
  }, [selectedTeam, users]);
  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={handleClose}
      title={isEdit ? "Update Team" : "Add New Team"}
      // useCustomHeader
      showTitle={true}
      hideButton={true}
      sx={{ width: 500 }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <CustomModalHeader
          onClose={handleClose}
          title={isEdit ? "Update Team" : "Add New Team"}
          saveBtnIsLoading={loading}
        /> */}
        <CreateTeamForm
          register={register}
          errors={errors}
          isEdit={isEdit}
          employee={employees}
          setEmployee={setEmployee}
          watch={watch}
          users={users}
          loading={loading}
          handleClose={handleClose}
          contentLoading={contentLoading}
          error={error}
          lead={lead}
          setLead={setLead}
          handleUserModalOpen={handleUserModalOpen}
        />
      </form>
      <AdminUsersModals
        onClose={handleUserModalClose}
        modalAction={CREATE_ACTION}
        isOpen={openUserModal}
        handleSuccess={getUsers}
      />
    </DefaultModal>
  );
};

export default CreateTeamModal;
